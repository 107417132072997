<template>
  <div class="animated fadeIn">
    <loading v-if="vLoading" />
    <v-dialog v-model="payDialog" width="650">
      <v-card class="dialog-card">
        <v-card-title>
          <h4 class="primary--text">پرداخت درخواست</h4>
        </v-card-title>
        <v-card-text class="pb-0">
          <h6>
            <v-row>
              <v-col>نام مراجعه کننده: {{ selected.name }}</v-col>
              <v-col>شماره آزمایش پذیرش: {{ selected.labTermNumber }}</v-col>
            </v-row>
            <v-row>
              <v-col
                >مبلغ: {{ Number(selected.cost).toLocaleString() }} ریال</v-col
              >
              <v-col
                >مبلغ پرداخت شده:
                {{ Number(selected.paidCost).toLocaleString() }} ریال</v-col
              >
            </v-row>
            <v-row>
              <v-col
                >مانده:
                {{ Number(selected.remained).toLocaleString() }} ریال</v-col
              >
            </v-row>
          </h6>

          <hr class="my-5" />
          <vuetify-money
            v-model="payAmount"
            label="مبلغ (ریال)"
            :valueWhenIsEmpty="whenIsEmpty"
            :options="vMoneyOptions"
            type="number"
            :rules="[validCost]"
            outlined
            dense
            hide-details
          />
          <div class="red--text text-right" v-if="payAmount && !validCost()">
            مبلغ وارد شده از مبلغ مانده بیشتر است
          </div>
          <v-row class="time-row mt-1">
            <v-col
              ><v-select
                v-model="transferType"
                :items="paymentTypes"
                item-text="text"
                item-value="value"
                label="نحوه پرداخت"
                outlined
                dense
                hide-details
              ></v-select>
            </v-col>
          </v-row>
          <v-row
            class="time-row"
            v-if="
              transferType == 'card' ||
              transferType == 'cheque' ||
              transferType == 'cash-pos'
            "
          >
            <v-col>
              <div v-if="transferType == 'card'">
                <div class="grey-div">
                  <h5 class="primary--text mb-2">اطلاعات کارت‌ها</h5>
                  <v-text-field
                    outlined
                    dense
                    type="number"
                    class="mb-4"
                    v-model="cardNumberSender"
                    label="شماره کارت فرستنده*"
                  >
                  </v-text-field>
                  <v-text-field
                    outlined
                    dense
                    type="number"
                    v-model="cardNumberReciever"
                    label="شماره کارت گیرنده*"
                  >
                  </v-text-field>
                </div>
              </div>
              <div v-if="transferType == 'cash-pos'">
                <div class="grey-div">
                  <h6 class="py-3 red--text" v-if="!cashPosValid('payment')">
                    ! مجموع نقدی و کارتخوان با مبلغ نهایی برابر نمی‌باشد.
                  </h6>
                  <vuetify-money
                    v-model="posAmount"
                    label="*مبلغ کارتخوان(ریال)"
                    :valueWhenIsEmpty="whenIsEmpty"
                    :options="vMoneyOptions"
                    type="number"
                    @input="onPosInput('payment')"
                    outlined
                    dense
                    class="mx-auto mb-2 details-hidden"
                  />
                  <vuetify-money
                    v-model="cashAmount"
                    label="*مبلغ نقدی(ریال)"
                    :valueWhenIsEmpty="whenIsEmpty"
                    :options="vMoneyOptions"
                    type="number"
                    outlined
                    dense
                    class="mx-auto details-hidden"
                  />
                </div>
              </div>
              <div v-if="transferType == 'cheque'">
                <div class="grey-div">
                  <h5 class="primary--text mb-2">اطلاعات چک</h5>
                  <v-text-field
                    outlined
                    dense
                    v-model="chequeBankName"
                    label="نام بانک*"
                    class="mb-4"
                  >
                  </v-text-field>
                  <v-text-field
                    outlined
                    dense
                    v-model="chequeNumber"
                    label="شماره چک*"
                    class="mb-4"
                  >
                  </v-text-field>
                  <v-text-field
                    outlined
                    dense
                    v-model="chequeSayyad"
                    label="شناسه صیاد"
                    class="mb-4"
                  >
                  </v-text-field>
                  <span id="newChequeDate">
                    <v-text-field
                      outlined
                      dense
                      type="text"
                      append-icon="calendar_today"
                      v-model="chequeDueDate"
                      label=" تاریخ چک*"
                      :editable="true"
                      class="date-input mb-4"
                    >
                    </v-text-field>
                  </span>

                  <date-picker
                    v-model="chequeDueDate"
                    element="newChequeDate"
                    color="#00a7b7"
                  />
                  <v-textarea
                    outlined
                    dense
                    type="text"
                    v-model="chequeDescription"
                    label=" توضیحات چک"
                  >
                  </v-textarea>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="mt-3">
          <v-spacer></v-spacer>
          <v-btn
            class="primary-btn"
            outlined
            :loading="isPaying"
            @click="submitPayment()"
            :disabled="!checkCost() || !cashPosValid('payment')"
          >
            ثبت پرداخت
          </v-btn>
          <v-btn
            class="red-btn"
            outlined
            :disabled="isPaying"
            @click="payDialog = false"
          >
            لغو
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="refundDialog" width="600">
      <v-card class="dialog-card">
        <v-card-title>
          <h4 class="primary--text">بازپرداخت</h4>
        </v-card-title>
        <v-card-text class="pb-0">
          <h6>
            <v-row>
              <v-col class="pb-0">
                <span
                  >شما در حال بازپرداخت درخواست با شماره پذیرش
                  <b>{{ selected.labTermNumber }}</b> هستید.</span
                >
              </v-col>
            </v-row>
            <v-row>
              <v-col
                >مبلغ:
                {{ Number(selected.payment).toLocaleString() }} ریال</v-col
              >
            </v-row>
            <v-row class="mt-2">
              <v-col
                ><v-select
                  v-model="transferType"
                  :items="paymentTypes"
                  item-text="text"
                  item-value="value"
                  label="نحوه پرداخت"
                  outlined
                  dense
                  hide-details
                ></v-select>
              </v-col>
            </v-row>
            <v-row
              class="time-row"
              v-if="
                transferType == 'card' ||
                transferType == 'cheque' ||
                transferType == 'cash-pos'
              "
            >
              <v-col>
                <div v-if="transferType == 'card'">
                  <div class="grey-div">
                    <h5 class="primary--text mb-2">اطلاعات کارت‌ها</h5>
                    <v-text-field
                      outlined
                      dense
                      type="number"
                      class="mb-4"
                      v-model="cardNumberSender"
                      label="شماره کارت فرستنده*"
                    >
                    </v-text-field>
                    <v-text-field
                      outlined
                      dense
                      type="number"
                      v-model="cardNumberReciever"
                      label="شماره کارت گیرنده*"
                    >
                    </v-text-field>
                  </div>
                </div>
                <div v-if="transferType == 'cash-pos'">
                  <div class="grey-div">
                    <h6 class="mb-4 red--text" v-if="!cashPosValid('refund')">
                      ! مجموع نقدی و کارتخوان با مبلغ نهایی برابر نمی‌باشد.
                    </h6>
                    <vuetify-money
                      v-model="posAmount"
                      label="*مبلغ کارتخوان(ریال)"
                      :valueWhenIsEmpty="whenIsEmpty"
                      :options="vMoneyOptions"
                      type="number"
                      @input="onPosInput('refund')"
                      outlined
                      dense
                      class="mx-auto mb-2 details-hidden"
                    />
                    <vuetify-money
                      v-model="cashAmount"
                      label="*مبلغ نقدی(ریال)"
                      :valueWhenIsEmpty="whenIsEmpty"
                      :options="vMoneyOptions"
                      type="number"
                      outlined
                      dense
                      class="mx-auto details-hidden"
                    />
                  </div>
                </div>

                <div v-if="transferType == 'cheque'">
                  <div class="grey-div">
                    <h5 class="primary--text mb-2">اطلاعات چک</h5>
                    <v-text-field
                      outlined
                      dense
                      v-model="chequeBankName"
                      label="نام بانک*"
                      class="mb-4"
                    >
                    </v-text-field>
                    <v-text-field
                      outlined
                      dense
                      v-model="chequeNumber"
                      label="شماره چک*"
                      class="mb-4"
                    >
                    </v-text-field>
                    <v-text-field
                      outlined
                      dense
                      v-model="chequeSayyad"
                      label="شناسه صیاد"
                      class="mb-4"
                    >
                    </v-text-field>
                    <span id="newChequeDate">
                      <v-text-field
                        outlined
                        dense
                        type="text"
                        append-icon="calendar_today"
                        v-model="chequeDueDate"
                        label=" تاریخ چک*"
                        :editable="true"
                        class="date-input mb-4"
                      >
                      </v-text-field>
                    </span>

                    <date-picker
                      v-model="chequeDueDate"
                      element="newChequeDate"
                      color="#00a7b7"
                    />
                    <v-textarea
                      outlined
                      dense
                      type="text"
                      v-model="chequeDescription"
                      label="توضیحات چک"
                    >
                    </v-textarea>
                  </div>
                </div>
              </v-col>
            </v-row>
          </h6>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="primary-btn"
            outlined
            :loading="isRefunding"
            :disabled="!refundDisabled() || !cashPosValid('refund')"
            @click="submitRefund()"
          >
            ثبت بازپرداخت
          </v-btn>
          <v-btn
            class="red-btn"
            outlined
            :disabled="isRefunding"
            @click="refundDialog = false"
          >
            لغو
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-col cols="12">
      <v-card-title style="background-color: transparent !important">
        <h3>خدمات آزمایشگاه</h3>
      </v-card-title>

      <v-tabs fixed-tabs background-color="transparent" v-model="tab">
        <v-tab @click="getServicesInfoSeperately" class="tab-title">
          پرداخت‌ها
        </v-tab>
        <v-tab @click="getRefundLists" class="tab-title"> بازپرداخت‌ها </v-tab>
      </v-tabs>
      <v-card class="br-card tab-item">
        <v-tabs-items v-model="tab">
          <!-- پرداخت‌ها -->
          <v-tab-item>
            <mobile-text v-if="deviceType == 'mobile'" :title="''" />
            <v-card v-else class="cards pa-3">
              <div style="float: left" class="me-3 ms-3">
                <v-btn
                  text
                  class="refresh-btn pe-0 ps-0"
                  @click="getServicesInfoSeperately()"
                  :loading="Busy"
                  ><v-icon> refresh</v-icon></v-btn
                >
              </div>
              <v-card-text class="mt-4">
                <div>
                  <v-text-field
                    v-model="Filter"
                    label="جستجو"
                    single-line
                    hide-details
                    prepend-inner-icon="mdi-magnify"
                    class="text-right mb-2 mt-2 me-0 ms-0 search-input dr-search"
                    filled
                    rounded
                    clearable
                  ></v-text-field>
                  <b-table
                    responsive
                    show-empty
                    head-variant="light"
                    :fields="Fields"
                    :items="Items"
                    empty-text="موردی برای نمایش وجود ندارد"
                    empty-filtered-text="موردی برای نمایش وجود ندارد"
                    :filter="Filter"
                    :busy="Busy"
                    :current-page="CurrentPage"
                    :per-page="PerPage"
                    @filtered="onFiltered"
                  >
                    <template v-slot:head()="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{ data.label }}
                      </div>
                    </template>
                    <template v-slot:cell()="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{
                          typeof data.value === "number"
                            ? Number(data.value).toLocaleString()
                            : data.value
                        }}
                      </div>
                    </template>
                    <template v-slot:cell(operation)="data">
                      <div style="text-align: center; vertical-align: middle">
                        <v-btn
                          class="primary-btn pa-2"
                          @click="openPayModal(data.item)"
                          style="width: 100%; margin-top: 2px"
                          ><v-icon>mdi-cash-multiple</v-icon>
                        </v-btn>
                      </div>
                    </template>
                    <template v-slot:cell(index)="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{ data.index + PerPage * (CurrentPage - 1) + 1 }}
                      </div>
                    </template>
                    <div
                      slot="table-busy"
                      class="text-center text-primary my-2"
                    >
                      <v-progress-circular
                        indeterminate
                        color="primary"
                        class="align-middle"
                      ></v-progress-circular>
                    </div>
                  </b-table>
                  <v-pagination
                    v-model="CurrentPage"
                    :length="Math.ceil(TotalRows / PerPage)"
                    :total-visible="5"
                    prev-icon="arrow_back"
                    next-icon="arrow_forward"
                  ></v-pagination>
                  <v-select
                    label="تعداد در هر صفحه:"
                    style="width: 150px"
                    v-model="PerPage"
                    :items="perPageOptions"
                    item-text="text"
                    item-value="value"
                  ></v-select>
                </div>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <!--بازپرداخت‌ها-->
          <v-tab-item>
            <mobile-text v-if="deviceType == 'mobile'" :title="''" />
            <v-card class="cards pa-3" v-else>
              <div style="float: left" class="me-3 ms-3">
                <v-btn
                  text
                  class="refresh-btn pe-0 ps-0"
                  @click="getRefundLists()"
                  :loading="reBusy"
                  ><v-icon> refresh</v-icon></v-btn
                >
              </div>
              <v-card-text class="mt-4">
                <div>
                  <v-text-field
                    v-model="reFilter"
                    label="جستجو"
                    single-line
                    hide-details
                    prepend-inner-icon="mdi-magnify"
                    class="text-right mb-2 mt-2 me-0 ms-0 search-input dr-search"
                    filled
                    rounded
                    clearable
                  ></v-text-field>
                  <b-table
                    responsive
                    show-empty
                    head-variant="light"
                    :fields="reFields"
                    :items="refunds"
                    empty-text="موردی برای نمایش وجود ندارد"
                    empty-filtered-text="موردی برای نمایش وجود ندارد"
                    :filter="reFilter"
                    :busy="reBusy"
                    :current-page="reCurrentPage"
                    :per-page="rePerPage"
                    @filtered="reOnFiltered"
                  >
                    <template v-slot:head()="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{ data.label }}
                      </div>
                    </template>
                    <template v-slot:cell()="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{
                          typeof data.value === "number"
                            ? Number(data.value).toLocaleString()
                            : data.value
                        }}
                      </div>
                    </template>
                    <template v-slot:cell(operation)="data">
                      <div style="text-align: center; vertical-align: middle">
                        <v-btn
                          class="primary-btn pa-2"
                          @click="openRefundDialog(data.item)"
                          style="width: 100%; margin-top: 2px"
                          ><v-icon>mdi-cash-multiple</v-icon>
                        </v-btn>
                      </div>
                    </template>
                    <template v-slot:cell(index)="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{ data.index + rePerPage * (reCurrentPage - 1) + 1 }}
                      </div>
                    </template>
                    <div
                      slot="table-busy"
                      class="text-center text-primary my-2"
                    >
                      <v-progress-circular
                        indeterminate
                        color="primary"
                        class="align-middle"
                      ></v-progress-circular>
                    </div>
                  </b-table>
                  <v-pagination
                    v-model="reCurrentPage"
                    :length="Math.ceil(reTotalRows / rePerPage)"
                    :total-visible="5"
                    prev-icon="arrow_back"
                    next-icon="arrow_forward"
                  ></v-pagination>
                  <v-select
                    label="تعداد در هر صفحه:"
                    style="width: 150px"
                    v-model="rePerPage"
                    :items="perPageOptions"
                    item-text="text"
                    item-value="value"
                  ></v-select>
                </div>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-col>
    <!-- receipt -->
    <v-card
      class="pa-2"
      v-show="false"
      style="float: left"
      id="Receipt"
      v-if="isMounted"
    >
      <div class="container mb-0" style="border: 2px solid black">
        <div
          style="
            text-align: center;
            margin: auto;
            font-size: 15px;
            border-bottom: 4px solid black;
            padding: 20px;
          "
        >
          <img src="/receiptLogo.png" width="100" height="100" alt="" />
          <br />
          درمانگاه شبانه روزی پارسیان البرز
        </div>
        <div
          style="direction: rtl; font-weight: 900; padding-right: 10px"
          class="ma-3"
        >
          <br />
          {{ "نام مراجعه کننده: " + selected.name }}
          <br />
          {{ "شماره آزمایش پذیرش: " + selected.labTermNumber }}
          <br />
          {{ "مبلغ : " + Number(selected.cost).toLocaleString() + "ریال" }}
          <br />
          {{
            " مبلغ پرداخت شده: " +
            (Number(selected.paidCost) + Number(payAmount)).toLocaleString() +
            "ریال"
          }}
          <br />
          {{
            "مانده: " +
            (Number(selected.remained) - Number(payAmount)).toLocaleString() +
            "ریال"
          }}
          <br />
          {{ " تاریخ: " + currentDate }}
          <br />
          {{
            "نوع پرداخت: " +
            paymentTypes.filter((x) => x.value == transferType)[0].text
          }}
          <br />
          <div
            v-if="
              transferType == 'card' ||
              transferType == 'cheque' ||
              transferType == 'cash-pos'
            "
          >
            <div v-if="transferType == 'card'">
              شماره کارت فرستنده:
              {{ cardNumberSender ? cardNumberSender : "-" }}
              <br />
              شماره کارت فرستنده:
              {{ cardNumberReciever ? cardNumberReciever : "-" }}
            </div>
            <div v-if="transferType == 'cash-pos'">
              مبلغ کارتخوان:
              {{ posAmount ? Number(posAmount).toLocaleString() : "-" }} ریال
              <br />
              مبلغ نقدی:
              {{ cashAmount ? Number(cashAmount).toLocaleString() : "-" }} ریال
            </div>
            <div v-if="transferType == 'cheque'">
              نام بانک: {{ chequeBankName ? chequeBankName : "-" }}
              <br />
              شماره چک: {{ chequeNumber ? chequeNumber : "-" }}
              <br />
              شناسه صیاد: {{ chequeSayyad ? chequeSayyad : "-" }}
              <br />
              تاریخ چک: {{ chequeDueDate ? chequeDueDate : "-" }}
              <br />
              توضیحات چک: {{ chequeDescription ? chequeDescription : "-" }}
            </div>
          </div>
        </div>
        <br />
      </div>
      <br />
      <!-- <barcode value="http://my.parsianalborz.com" displayValue="false" width="2" height="60">
        </barcode>   -->
      <h4 style="text-align: center">http://my.parsianalborz.com</h4>
    </v-card>
    <!-- receipt -->
  </div>
</template>
<script>
import moment from "moment-jalaali";
import VueBarcode from "vue-barcode";
import VuePersianDatetimePicker from "vue-persian-datetime-picker";
import { mapGetters } from "vuex";

const MobileText = () => import("@/components/MobileText");
export default {
  components: {
    barcode: VueBarcode,
    datePicker: VuePersianDatetimePicker,
    MobileText,
  },
  data() {
    return {
      payDialog: false,
      payAmount: "",
      selected: {},
      reCurrentPage: 1,
      CurrentPage: 1,
      rePerPage: 10,
      PerPage: 10,
      tab: null,
      perPageOptions: [
        { value: 10, text: "10" },
        { value: 25, text: "25" },
        { value: 50, text: "50" },
        { value: 100, text: "100" },
      ],
      transferType: "pos",
      cardNumberSender: "",
      cardNumberReciever: "",
      chequeBankName: "",
      chequeAmount: "",
      chequeNumber: "",
      chequeSayyad: "",
      chequeDueDate: "",
      chequeDescription: "",
      cashAmount: 0,
      posAmount: 0,
      TotalRows: "",
      reTotalRows: "",
      Filter: "",
      reFilter: "",
      Busy: false,
      reBusy: false,
      isPaying: false,
      isRefunding: false,
      Fields: [
        { key: "index", label: "#" },
        { key: "name", label: "نام بیمار" },
        { key: "labTermNumber", label: "شماره پذیرش آزمایش" },
        { key: "date", label: "تاریخ و ساعت" },
        { key: "cost", label: "مبلغ (ریال)" },
        { key: "paidCost", label: "مبلغ پرداخت شده (ریال)" },
        { key: "operation", label: "پرداخت" },
      ],
      reFields: [
        { key: "index", label: "#" },
        { key: "name", label: "نام بیمار" },
        { key: "labTermNumber", label: "شماره پذیرش آزمایش" },
        { key: "payment", label: "مبلغ (ریال)" },
        { key: "operation", label: "بازپرداخت" },
      ],
      excelFields: [
        { field: "name", label: "نام بیمار" },
        { field: "labTermNumber", label: "شماره پذیرش آزمایش" },
        { field: "date", label: "تاریخ و ساعت" },
        { field: "cost", label: "مبلغ (ریال)" },
        { field: "paidCost", label: "مبلغ پرداخت شده (ریال)" },
      ],
      Items: [],
      refunds: [],
      role: "",
      currentDate: moment(new Date()).format("jYYYY-jMM-jDD"),
      reciept: {},
      isMounted: false,
      refundDialog: false,
    };
  },

  methods: {
    getServices() {
      //NOTE getting services info by start date and end date when page mount
      this.Busy = true;
      this.$http
        .post(
          this.baseUrl + "/clinic/lab/payment/unpaidLists",
          {},
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          this.Busy = false;
          if (res.status == 200) {
            this.Items = res.data;
            this.isMounted = true;
            this.TotalRows = this.Items.length;
            this.CurrentPage = 1;
          } else {
            this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
          }
        })
        .catch((err) => {
          this.Busy = false;
          this.toast("خطا: " + err.response.data, "error");
        });
    },
    getServicesInfoSeperately() {
      this.Busy = true;
      this.$http
        .post(
          this.baseUrl + "/clinic/lab/payment/unpaidLists",
          {},
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          this.Busy = false;
          if (res.status == 200) {
            this.Items = res.data;
            this.TotalRows = this.Items.length;
            this.CurrentPage = 1;
          } else {
            this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
          }
        })
        .catch((err) => {
          this.Busy = false;
          this.toast("خطا: " + err.response.data, "error");
        });
    },
    openPayModal(item) {
      this.selected = JSON.parse(JSON.stringify(item));
      this.selected.remained = this.selected.cost - this.selected.paidCost;
      this.payAmount = this.selected.remained;
      this.transferType = "pos";
      this.cardNumberSender = "";
      this.cardNumberReciever = "";
      this.chequeBankName = "";
      this.chequeAmount = "";
      this.chequeNumber = "";
      this.chequeSayyad = "";
      this.chequeDueDate = "";
      this.chequeDueDate = "";
      this.chequeDescription = "";
      this.cashAmount = 0;
      this.posAmount = 0;
      this.payDialog = true;
    },
    openRefundDialog(item) {
      this.selected = JSON.parse(JSON.stringify(item));
      this.transferType = "pos";
      this.cardNumberSender = "";
      this.cardNumberReciever = "";
      this.chequeBankName = "";
      this.chequeAmount = "";
      this.chequeNumber = "";
      this.chequeSayyad = "";
      this.chequeDueDate = "";
      this.chequeDueDate = "";
      this.chequeDescription = "";
      this.cashAmount = 0;
      this.posAmount = 0;
      this.refundDialog = true;
    },
    submitPayment() {
      this.isPaying = true;
      this.$http
        .post(
          this.baseUrl + "/clinic/lab/payment/pay",
          {
            labRequestId: this.selected.id,
            paidCost: this.payAmount,
            transferType: this.transferType,
            chequeAmount: this.transferType == "cheque" ? this.payAmount : "",
            chequeNumber:
              this.transferType == "cheque" ? this.chequeNumber : "",
            chequeBankName:
              this.transferType == "cheque" ? this.chequeBankName : "",
            chequeDueDate:
              this.transferType == "cheque" ? this.chequeDueDate : "",
            chequeSayyad:
              this.transferType == "cheque" ? this.chequeSayyad : "",
            chequeDescription:
              this.transferType == "cheque" ? this.chequeDescription : "",
            cardNumberSender:
              this.transferType == "card" ? this.cardNumberSender : "",
            cardNumberReciever:
              this.transferType == "card" ? this.cardNumberReciever : "",
            cash: this.transferType == "cash-pos" ? this.cashAmount : "",
            pos: this.transferType == "cash-pos" ? this.posAmount : "",
          },
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          this.isPaying = false;
          if (res.status == 200) {
            this.toast(res.data, "success");
            this.payDialog = false;
            this.getServices();
            this.printReceipt();
            this.isMounted = false;
          } else {
            this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
          }
        })
        .catch((err) => {
          this.isPaying = false;
          this.toast("خطا: " + err.response.data, "error");
        });
    },
    submitRefund() {
      this.isRefunding = true;
      this.$http
        .post(
          this.baseUrl + "/clinic/lab/payment/refund",
          {
            labRequestId: this.selected.id,
            transferType: this.transferType,
            chequeAmount:
              this.transferType == "cheque" ? this.selected.payment : "",
            chequeNumber:
              this.transferType == "cheque" ? this.chequeNumber : "",
            chequeBankName:
              this.transferType == "cheque" ? this.chequeBankName : "",
            chequeDueDate:
              this.transferType == "cheque" ? this.chequeDueDate : "",
            chequeSayyad:
              this.transferType == "cheque" ? this.chequeSayyad : "",
            chequeDescription:
              this.transferType == "cheque" ? this.chequeDescription : "",
            cardNumberSender:
              this.transferType == "card" ? this.cardNumberSender : "",
            cardNumberReciever:
              this.transferType == "card" ? this.cardNumberReciever : "",
            cash: this.transferType == "cash-pos" ? this.cashAmount : "",
            pos: this.transferType == "cash-pos" ? this.posAmount : "",
          },
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          this.isRefunding = false;
          if (res.status == 200) {
            this.toast(res.data, "success");
            this.refundDialog = false;
            this.getRefundLists();
            this.isMounted = false;
          } else {
            this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
          }
        })
        .catch((err) => {
          this.isRefunding = false;
          this.toast("خطا: " + err.response.data, "error");
        });
    },

    validCost(cost) {
      // NOTE (m-isID) to check requested amount is not bigger than remained cost
      return this.payAmount
        ? this.payAmount <= this.selected.remained
          ? true
          : false
        : true;
    },
    checkCost() {
      // NOTE (m-isID) to check the validity of the national code
      if (this.payAmount) {
        if (this.payAmount <= this.selected.remained) {
          if (this.transferType == "card") {
            if (this.cardNumberSender && this.cardNumberReciever) {
              return true;
            } else return false;
          } else if (this.transferType == "cheque") {
            if (
              this.chequeBankName &&
              this.chequeNumber &&
              this.chequeDueDate
            ) {
              return true;
            } else return false;
          } else if (this.transferType == "cash-pos") {
            if (this.cashAmount && this.posAmount) {
              return true;
            } else return false;
          } else return true;
        } else return false;
      } else return false;
    },
    refundDisabled() {
      // NOTE (m-isID) to check the validity of the national code
      if (this.transferType == "card") {
        if (this.cardNumberSender && this.cardNumberReciever) {
          return true;
        } else return false;
      } else if (this.transferType == "cheque") {
        if (this.chequeBankName && this.chequeNumber && this.chequeDueDate) {
          return true;
        } else return false;
      } else return true;
    },
    getRefundLists() {
      this.$http
        .post(
          this.baseUrl + "/clinic/lab/payment/needRefundLists",
          {},
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          this.isPaying = false;
          if (res.status == 200) {
            this.refunds = res.data;
            this.isMounted = true;
            this.reTotalRows = this.refunds.length;
            this.reCurrentPage = 1;
          } else {
            this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
          }
        })
        .catch((err) => {
          this.isPaying = false;
          this.toast("خطا: " + err.response.data, "error");
        });
    },
    onPosInput(type) {
      if (type == "payment") {
        if (parseInt(this.posAmount) < parseInt(this.payAmount)) {
          this.cashAmount = parseInt(this.payAmount) - parseInt(this.posAmount);
        } else {
          this.cashAmount = 0;
        }
      } else {
        if (parseInt(this.posAmount) < parseInt(this.selected.payment)) {
          this.cashAmount =
            parseInt(this.selected.payment) - parseInt(this.posAmount);
        } else {
          this.cashAmount = 0;
        }
      }
    },
    cashPosValid(type) {
      if (this.transferType == "cash-pos") {
        if (this.cashAmount && this.posAmount) {
          if (type == "payment") {
            if (
              parseInt(this.payAmount) ==
              parseInt(this.cashAmount) + parseInt(this.posAmount)
            ) {
              return true;
            } else return false;
          } else if (type == "refund") {
            return parseInt(this.cashAmount) + parseInt(this.posAmount) ==
              parseInt(this.selected.payment)
              ? true
              : false;
          }
        } else return false;
      } else {
        return true;
      }
    },
    printReceipt() {
      // printing receipt information for user
      var myWindow = window.open("#", "Receipt", "height=auto,width=800");
      myWindow.document.write(
        "<html><head><link rel='stylesheet' href='/receipt.css'><title>Receipt</title>"
      );
      myWindow.document.write("</head><body>");
      myWindow.document.write(document.getElementById("Receipt").innerHTML);
      myWindow.document.write("</body></html>");
      myWindow.document.close();

      myWindow.onload = function () {
        myWindow.focus();
        setTimeout(() => {
          myWindow.print();
          myWindow.close();
        }, 500);
      };
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.TotalRows = filteredItems.length;
      this.CurrentPage = 1;
    },
    reOnFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.reTotalRows = filteredItems.length;
      this.reCurrentPage = 1;
    },
  },
  computed: {
    ...mapGetters(["paymentTypes"]),
  },
  mounted() {
    this.role = localStorage.getItem("role");
    this.getServices();
  },
};
</script>
